<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Probation Reviews</span>
          <v-spacer></v-spacer>
          <!-- <v-btn
                  outlined
                  color="green"
                  @click="$router.push('/MyLoanApplications')"
                  >Loan Applications</v-btn
                > -->
          <v-flex xs12 align-self-center py-2>
            <!-- <v-layout wrap justify-end>
                <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                  <v-text-field
                    v-model="keyword"
                    outlined
                    dense
                    hide-details
                    class="rounded-xl"
                    label="Search"
                    clearable
                  >
                  </v-text-field>
                </v-flex>
              </v-layout> -->
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="loanList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sl.No</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Employee ID</th>
                    <th class="text-left">Grade</th>
                    <th class="text-left">Reviewed By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in loanList"
                    :key="i"
                    @click="handleRowClick(item)"
                    style="cursor: pointer"
                  >
                    <td>{{ i + 1 }}.</td>
                    <td>
                      <span
                        v-if="item.employeeId"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId.name }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.employeeId"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId.employeeId }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.employeeId"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId.grade }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.reviewedBy"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.reviewedBy.name }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
      <script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      keyword: null,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      idd: null,
      loanList: [],
      data: [],
      //view doc
      curitem: [],
      docdialog: false,
      dialog2: false,
      doc: null,

      //
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      DataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      status: null,

      statusList: ["Pending", "Approved", "Rejected"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,
      deleteDialog: false,
      itemToDelete: null,
      releaseDialog: false,
      itemToRelease: null,
    };
  },
  components: {
    // ImageComp,
  },
  watch: {
    keyword() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        // url: "/salaryAdvance_Loans/own/list",
        url: "/probationreviewform/list",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // keyword: this.keyword,
          page: this.currentPage,
          limit: 20,
          employeeId: this.$route.query.employeeId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.loanList = response.data.data;
          this.totalData = response.data.totalData;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
    handleRowClick(item) {
    if (item.employeeId && item.employeeId.grade) {
      const grade = item.employeeId.grade.toUpperCase();
      if (['A', 'B', 'C', 'D'].includes(grade)) {
        this.$router.push({ path: '/probationReviewView', query: { employeeId: item.employeeId._id } });
      } else {
        this.$router.push({ path: '/probationReviewViewE', query: { employeeId: item.employeeId._id } });
      }
    }
  },
  },
};
</script>
      